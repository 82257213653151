import firebase from 'firebase';

// Initialize Firebase
var config = {
    apiKey            : "AIzaSyDfu5wpslZMmZdCbddcJ9JX26sBoboWTsM",
    authDomain        : "tiz181214-app-calendrier.firebaseapp.com",
    databaseURL       : "https://tiz181214-app-calendrier.firebaseio.com",
    projectId         : "tiz181214-app-calendrier",
    storageBucket     : "tiz181214-app-calendrier.appspot.com",
    messagingSenderId : "46827388786"
}; 

firebase.initializeApp(config);
const db = firebase.firestore();
export default db;