
class StringService{

   /* Format the categories */
   formatCategory(data){
      switch (data) {
         case "Rentrée":                           return "rentree";
         case "Fête":                              return "fete";         
         case "Sport":                             return "sport";    
         case "Événement":                         return "evenement";  
         case "Commercial":                        return "commercial";
         case "Journée mondiale / internationale": return "mondiale"; 
         case "Culture":                           return "culture";   
         case "Cinéma":                            return "cinema";    
         case "Jeu vidéo":                         return "jeu_video";
         case "Tiz":                               return "tiz";
         default : return data;
      }
   }

   /* Remove diatrics (accents) */
   removeAccents(string){
      return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
   }
}

export default StringService;
