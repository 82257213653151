import db from './FirebaseCloudService';


class ApiService{

   /*-------------------------------------------------*/
   /*--------------------- ADD -----------------------*/
   /*-------------------------------------------------*/

   /* Set General Event */
   async setGeneralEvent(dateEvents){
      if(dateEvents.events.length < 2){
         db.collection("DateEvents").doc(dateEvents.date.toString()).set({
            "base": true,        
            "date": dateEvents.date,
            "events": [
               {
                  "name"     : dateEvents.events[0].name,
                  "category" : dateEvents.events[0].category
               }
            ],
            "ferie": dateEvents.ferie
         }).then(docRef => {
            return true;
         }).catch(err => { 
            console.error(err);
            return false;
         });
      }else{
         return db.collection("DateEvents").doc(dateEvents.date.toString()).set({
            "base" : true,        
            "date" : dateEvents.date,
            "events" : [
               {
                  "name"     : dateEvents.events[0].name,
                  "category" : dateEvents.events[0].category
               },
               {
                  "name"     : dateEvents.events[1].name,
                  "category" : dateEvents.events[1].category
               },
            ],
            "ferie" : dateEvents.ferie
         }).then(docRef => {
            return true;
         }).catch(err => { 
            console.error(err);
            return false;
         });
      }
   }

   /* Set Tiz Event */
   async setTizEvent(dateEvents){
      db.collection("TizEvents").doc(dateEvents.date.toString()).set({
         "date"   : dateEvents.date,
         "events" : [
            {
               "name"     : dateEvents.events[0].name,
               "category" : dateEvents.events[0].category
            }
         ],
         "link": dateEvents.link
     }).then(docRef => {
         return true;
      }).catch(err => { 
         console.error(err);
         return false;
      });
   }



   /*-------------------------------------------------*/
   /*-------------------- DELETE ---------------------*/
   /*-------------------------------------------------*/

   /* Delete events with specific year */
   async deleteYearEvents(type, yearToDelete){
      
      let collection;
      if(type === "tiz") collection = "TizEvents";
      else collection = "DateEvents";
      
      let batch = db.batch();
      db.collection(collection).get().then(snapshot => {
         snapshot.docs.forEach(doc => {
            if(doc.id.startsWith(yearToDelete)) batch.delete(doc.ref);
         });
         
         batch.commit();
         
      }).then(docRef => {
         return true;
      }).catch(err => { 
         console.error(err);
         return false;
      });
   }


   /* Delete events with specific day */
   async deleteDayEvents(type, dayToDelete){
      
      let collection;
      if(type === "tiz") collection = "TizEvents";
      else collection = "DateEvents";
      
      let batch = db.batch();
      db.collection(collection).get().then(snapshot => {
         snapshot.docs.forEach(doc => {
            if(doc.id === dayToDelete) batch.delete(doc.ref);
         });
         
         batch.commit();
         
      }).then(docRef => {
         return true;
      }).catch(err => { 
         console.error(err);
         return false;
      });
   }
}

export default ApiService;
