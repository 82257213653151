/** Natives **/
import React, { Component } from 'react';

/** SCSS **/
import './Main.scss';

/* Images */
import logoTiz  from '../../assets/logoTiz.png'; 
import download from '../../assets/download.png'; 
import easter   from '../../assets/Mj2iT9d23.gif'; 

/* Plugins */
import { Row, Col } from 'react-flexbox-grid';
import Papa         from 'papaparse';
import Konami       from 'react-konami-code'
import DatePicker   from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; 
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";

/* Services */
import ApiService from '../../services/ApiService';
import StringService from '../../services/StringService';
const apiService    = new ApiService();
const stringService = new StringService();

// Register
registerLocale('fr', fr);


class Main extends Component {
    constructor() {
        super();
        this.state = {
            listDateEvents   : undefined,
            formatDateEvents : undefined,
            isGenLoaded : false,
            isTizLoaded : false,
            info_text_add_GEN : "Pour ajouter un ou plusieurs événements généraux, merci d'insérer votre fichier .csv ci-dessous.",
            info_text_delete_day_GEN  : "Supprimer les événements généraux de la journée suivante :",
            info_text_delete_year_GEN : "Supprimer les événements généraux de l'année.",

            info_text_add_TIZ : "Pour ajouter un ou plusieurs événements tiz, merci d'insérer votre fichier .csv ci-dessous.",
            info_text_delete_day_TIZ  : "Supprimer les événements tiz de la journée suivante :",
            info_text_delete_year_TIZ : "Supprimer les événements tiz de l'année.",

            dayGenToDelete : new Date(),
            dayTizToDelete : new Date(),
            yearToDelete : '2020'
        };
        this._formatCSVDataGeneralEvents = this._formatCSVDataGeneralEvents.bind(this);
        this._formatCSVDataTizEvents     = this._formatCSVDataTizEvents.bind(this);
        this._handleChangeFile           = this._handleChangeFile.bind(this);

        this._handleSelectedDayToDelete = this._handleSelectedDayToDelete.bind(this);

        this._confirmToDeleteYearEvents  = this._confirmToDeleteYearEvents.bind(this);

        this._deleteDayData = this._deleteDayData.bind(this);
    }

    /*-------------------------------------------------*/
    /*---------------------- CSV ----------------------*/
    /*-------------------------------------------------*/

    /* Changing file */
    _handleChangeFile = (event, loadedState, infoTextState) => {
        var extension = event.target.files[0].name.split('.').pop().toLowerCase()
        if(extension !== 'csv'){
            this.setState({
                [loadedState] : false
            });
            return this.setState({ [infoTextState] : 'Veuillez importer un fichier .csv.' });
        }else{
            return this.setState({
                listDateEvents  : event.target.files[0],
                [infoTextState] : 'Fichier CSV importé.',
                [loadedState]   : true
            });
        }
    };


    /* Import the CSV and parse it */
    _importCSV(type) {
        const { listDateEvents } = this.state;
        Papa.parse(listDateEvents, {
            complete       : type === "general" ? this._formatCSVDataGeneralEvents : this._formatCSVDataTizEvents,
            skipEmptyLines : true,
            header         : true
        });
    };


    /* Format the CSV data to be sendable to database */
    _formatCSVDataGeneralEvents(result){
        let listData = result.data;
        let temp_format_dateEvents = [];

        console.log('listData',listData);

        try {
            listData.forEach((data) => {
                let dateParsed = "";
                let event1Cat  = "";
                let event2Cat  = "";
                let event1Name = "";
                let event2Name = "";
                let events = [];
                let ferie  = false;

                for(var propertyName in data){

                    // Date 
                    if(propertyName.toLowerCase().includes('date')){
                        let splitDate = data[propertyName].split("/");
                        dateParsed = parseInt(splitDate[2]+splitDate[1]+splitDate[0], 10);
                    }

                    // Events categories
                    if(stringService.removeAccents(propertyName).toLowerCase().includes('cat') && propertyName.toLowerCase().includes('1')){
                        event1Cat = stringService.formatCategory(data[propertyName])
                    }
                    if(stringService.removeAccents(propertyName).toLowerCase().includes('cat') && propertyName.toLowerCase().includes('2')){
                        event2Cat = stringService.formatCategory(data[propertyName])
                    }

                    // Events name
                    if(stringService.removeAccents(propertyName).toLowerCase().includes('even') && propertyName.toLowerCase().includes('1')){
                        event1Name = data[propertyName]
                    }
                    if(stringService.removeAccents(propertyName).toLowerCase().includes('even') && propertyName.toLowerCase().includes('2')){
                        event2Name = data[propertyName]
                    }

                    // Férié 
                    if(stringService.removeAccents(propertyName).toLowerCase().includes('ferie')){
                        if(data[propertyName] === "" 
                        || data[propertyName] === null) ferie = false;

                        if(data[propertyName].toLowerCase().includes("false") 
                        || data[propertyName].toLowerCase().includes("faux") 
                        || data[propertyName].toLowerCase().includes("non")) ferie = false;
                        
                        if(data[propertyName].toLowerCase().includes("true") 
                        || data[propertyName].toLowerCase().includes("vrai") 
                        || data[propertyName].toLowerCase().includes("oui")) ferie = true;
                    }
                }
    
                if(event1Name !== ""){
                    events.push({
                        category : event1Cat,
                        name     : event1Name
                    });
                }
                if(event2Name !== ""){
                    events.push({
                        category : event2Cat,
                        name     : event2Name
                    });
                }

                if(dateParsed !== "" && events.length){
                    temp_format_dateEvents.push({date:dateParsed, events:events, ferie:ferie})
                } 
            });

            console.log('temp_format_dateEvents',temp_format_dateEvents)
            this._sendEventToFirebase("gen", temp_format_dateEvents, "isGenLoaded", "info_text_add_GEN");

        }catch (e) {
            this.setState({ info_text_add_GEN : "Erreur d'importation des données."});
            console.log(e);
        }
    }


    /* Format the CSV data to be sendable to database */
    _formatCSVDataTizEvents(result){
        let listData = result.data;
        let temp_format_dateEvents = [];

        console.log('listData',listData);

        try {
            listData.forEach((data) => {
                let events     = [];
                let dateParsed = "";
                let link       = "";

                for(var propertyName in data){

                    // Date 
                    if(propertyName.toLowerCase().includes('date')){
                        let splitDate = data[propertyName].split("/");
                        dateParsed = parseInt(splitDate[2]+splitDate[1]+splitDate[0], 10);
                    }

                    // Event
                    if(stringService.removeAccents(propertyName).toLowerCase().includes('even')){
                        events.push({
                            category : "tiz",
                            name     : data[propertyName]
                        });
                    }

                    if(propertyName.toLowerCase().includes('lien') || propertyName.toLowerCase().includes('link')){
                        link = data[propertyName];
                    }
                }
               
                if(dateParsed !== "" && events.length) temp_format_dateEvents.push({
                    date   : dateParsed, 
                    events : events, 
                    link   : link
                });
            });
            console.log('temp_format_dateEvents',temp_format_dateEvents);
            this._sendEventToFirebase("tiz", temp_format_dateEvents, "isTizLoaded", "info_text_add_TIZ");

        }catch (e) {
            this.setState({info_text_add_TIZ : "Erreur d'importation des données."});
            console.log(e);
        }
    }


    /*-------------------------------------------------*/
    /*------------ Send data to Firebase --------------*/
    /*-------------------------------------------------*/


    /* Sending new data to Firebase */
    async _sendEventToFirebase(type, listDateEvents, loadedState, infoTextState) {
        let isError = false;

        for(let dateEvents of listDateEvents){
            let isSend;
            if(type === "tiz") isSend = await apiService.setTizEvent(dateEvents);
            else isSend = await apiService.setGeneralEvent(dateEvents);

            if (isSend === false) {
                isError = true;
                break;
            }
        }

        if(!isError){
            this.setState({[infoTextState] : "Base de données mise à jour.", [loadedState]:false });
        }else{
            this.setState({[infoTextState] : "Une erreur est survenue, veuillez réessayer.", [loadedState]:false});
        }
    }

    /*-------------------------------------------------*/
    /*-------------------- Delete ---------------------*/
    /*-------------------------------------------------*/

    /*-------------- DAY --------------*/

    /* Change the date selected */
    _handleSelectedDayToDelete = (date, dateState) => {
        this.setState({
            [dateState] : date
        });
    }

    /* Confirm dialog to delete day */
    _confirmToDeleteDayEvents(type, day) {
        let dateString = day.toLocaleDateString();
        let dateStringSplitted = dateString.split("/");
        let dateFirebase = dateStringSplitted[2]+dateStringSplitted[1]+dateStringSplitted[0];

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='popover-block'>
                        <h2>Êtes-vous sur de vouloir supprimer les événements {type} de la journée du {dateString} ?</h2>
                        <p>Cette action est irréversible.</p>
                        <Row>
                            <button className='popover-button no' onClick={onClose}>Non</button>
                            <button className='popover-button yes' onClick={() => {this._deleteDayData(type, dateString, dateFirebase); onClose();}}>Oui, supprimer</button>
                        </Row>
                    </div>
                );
            }
        });
    };


    /* Delete the day events data */
    async _deleteDayData (type, dateString, dateFirebase) {
        let isDelete = await apiService.deleteDayEvents(type, dateFirebase);

        let stateName;
        if(type === "tiz") stateName = "info_text_delete_day_TIZ";
        else stateName = "info_text_delete_day_GEN";

        if(!isDelete){
            this.setState({[stateName] : "Le "+dateString+" a bien été supprimé."})
        }else{
            this.setState({[stateName] : "Erreur de suppression des événements du "+dateString})
        }
    }


    /*-------------- YEAR --------------/*

    /* Select the year to delete year */
    _handleChangeYearToDelete = event => {
        this.setState({yearToDelete: event.target.value});
    }

    /* Confirm dialog to delete year*/
    _confirmToDeleteYearEvents(type) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='popover-block'>
                        <h2>Êtes-vous sur de vouloir supprimer les événements {type} de l'année {this.state.yearToDelete} ?</h2>
                        <p>Cette action est irréversible.</p>
                        <Row>
                            <button className='popover-button no' onClick={onClose}>Non</button>
                            <button className='popover-button yes' onClick={() => {this._deleteYearData(type); onClose();}}>Oui, supprimer</button>
                        </Row>
                    </div>
                );
            }
        });
    };

    /* Delete year the events data */
    _deleteYearData = async (type) =>  {
        let yearToDelete = this.state.yearToDelete;
        let isDelete = await apiService.deleteYearEvents(type, yearToDelete);

        let stateName;
        if(type === "tiz") stateName = "info_text_delete_year_TIZ";
        else stateName = "info_text_delete_year_GEN";

        if(!isDelete){
            this.setState({[stateName] : "Les événements de l'année "+yearToDelete+" ont bien été supprimés."})
        }else{
            this.setState({[stateName] : "Erreur de suppression des événements de l'année "+yearToDelete.toString()})
        }
    }

    /*-------------------------------------------------*/
    /*-------------------- Render ---------------------*/
    /*-------------------------------------------------*/

    render() {
        return (
           <div className="main-container">

                {/* TITLE */}
                <Row className="title-row">
                    <Col className="title-block" xs={12}>
                        <img src={logoTiz} className="title-block-img" alt="Agence Tiz" />
                    </Col>
                </Row> 


                {/* GENERAL EVENTS */}
                <div className="content-row">
                    <h2 className="main-title">CALENDRIER SOCIAL MÉDIA TIZ</h2>
                    <div className="content-col">
                        <h3 className="content-title">ÉVÉNEMENTS GÉNÉRAUX</h3>
                        <Row className="content-div">
                            <div className="content-div-item">
                                <h3 className="content-title">AJOUTER</h3>
                                <div className="divider"></div>
                                <div className="content-div-item-half">
                                    <p className="content-text">{this.state.info_text_add_GEN}</p>
                                    <input
                                        className   = "button-file"
                                        type        = "file"
                                        ref         = {input => {this.filesInput = input;}}
                                        name        = "file"
                                        placeholder = {null}
                                        onChange    = {(e) => this._handleChangeFile(e, "isGenLoaded", "info_text_add_GEN")}
                                    />
                                    {this.state.isGenLoaded && (<button className="button-action" onClick={() => this._importCSV("general")}>Importer dans la base de données <img className="icon" src={download} alt="importer" height="18" width="18"/></button>)}
                                </div>
                            </div>
                            <div className="content-div-item">
                                <h3 className="content-title">SUPPRIMER</h3>
                                <div className="divider"></div>
                                <div className="content-div-item-half">
                                    <p className="content-text">{this.state.info_text_delete_day_GEN}</p>
                                    <DatePicker
                                        selected={this.state.dayGenToDelete}
                                        onSelect={(e) => this._handleSelectedDayToDelete(e, "dayGenToDelete")} 
                                        dateFormat="P"
                                        locale="fr"
                                    />
                                    <button className="button-action" onClick={() => this._confirmToDeleteDayEvents("généraux", this.state.dayGenToDelete)}>Supprimer la journée</button>
                                </div>
                                <div className="divider accent"></div>
                                <div className="content-div-item-half">
                                    <p className="content-text">{this.state.info_text_delete_year_GEN}</p>
                                    <select value={this.state.yearToDelete} onChange={this._handleChangeYearToDelete}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                    </select>
                                    <button className="button-action" onClick={() => this._confirmToDeleteYearEvents("généraux")}>Supprimer l'année</button>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div> 


                {/* TIZ EVENTS */}
                <div className="content-row">
                    <div className="content-col accent">
                        <h3 className="content-title">ÉVÉNEMENTS TIZ</h3>
                        <Row className="content-div">
                            <div className="content-div-item">
                                <h3 className="content-title">AJOUTER</h3>
                                <div className="divider"></div>
                                <div className="content-div-item-half">
                                    <p className="content-text">{this.state.info_text_add_TIZ}</p>
                                    <input
                                        className   = "button-file"
                                        type        = "file"
                                        ref         = {input => {this.filesInput = input;}}
                                        name        = "file"
                                        placeholder = {null}
                                        onChange    = {(e) => this._handleChangeFile(e, "isTizLoaded", "info_text_add_TIZ")}
                                    />
                                    {this.state.isTizLoaded && (<button className="button-action" onClick={() => this._importCSV("tiz")}>Importer dans la base de données <img className="icon" src={download} alt="importer" height="18" width="18"/></button>)}
                                </div>
                            </div>
                            <div className="content-div-item">
                                <h3 className="content-title">SUPPRIMER</h3>
                                <div className="divider"></div>
                                <div className="content-div-item-half">
                                    <p className="content-text">{this.state.info_text_delete_day_TIZ}</p>
                                    <DatePicker
                                        selected={this.state.dayTizToDelete}
                                        onSelect={(e) => this._handleSelectedDayToDelete(e, "dayTizToDelete")}
                                        dateFormat="P"
                                        locale="fr"
                                    />
                                    <button className="button-action" onClick={() => this._confirmToDeleteDayEvents("tiz", this.state.dayTizToDelete)}>Supprimer la journée</button>
                                </div>
                                <div className="divider accent"></div>
                                <div className="content-div-item-half">
                                    <p className="content-text">{this.state.info_text_delete_year_TIZ}</p>
                                    <select value={this.state.yearToDelete} onChange={this._handleChangeYearToDelete}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                    </select>
                                    <button className="button-action" onClick={() => this._confirmToDeleteYearEvents("tiz")}>Supprimer l'année</button>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div> 


                {/* EASTER EGG */}
                <Konami className="konami-img">
                    <img src={easter} alt="woaw" />
                </Konami>
           </div>      
        );
    }
}

export default Main;


  